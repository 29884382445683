import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styles/styled-components';
import messages from '../messages';
import Kamereo from 'components/MainNav/Kamereo.svg';
import media from 'utils/mediaStyle';

import { gyomuLogo } from '../images';

const MessageWrapper = styled.div`
  position: relative;
  padding: 20px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #eaeaeb;
  background-color: #ffffff;
  ${media.lg`
    padding: 20px 120px;
    margin: 0 ;
  `};
`;
const Title = styled.p`
  line-height: 20px;
  font-size: 16px;
  font-weight: 600;
  ${media.lg`
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
  `};
`;
const Message = styled.p`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  ${media.lg`
    font-size: 16px;
  `};
`;

const KamereoLogoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  ${media.lg`
  gap: 40px; 
   `};
`;

const BrandLogo = styled.img`
  width: 120px;
  ${media.lg`
    width: auto;
   `};
`;
const GyomuLogo = styled.img`
  width: 115px;
  ${media.lg`
  width: 210px;
   `};
`;
const XCharacter = styled.div`
  font-size: 20px;
  ${media.lg`
  font-size: 50px;
   `};
`;

const GyomuSuperMessage = () => {
  const lang = window.localStorage.getItem('lang') || 'vi';

  return (
    <MessageWrapper>
      <Title>
        <FormattedMessage {...messages.collaboration} />
      </Title>
      <KamereoLogoContainer>
        <GyomuLogo src={gyomuLogo} alt="Gyomu Japan" />
        <XCharacter>X</XCharacter>
        <BrandLogo src={Kamereo} alt="KAMEREO - Đối tác cung cấp thực phẩm uy tín" />
      </KamereoLogoContainer>
      <Message>
        <FormattedMessage {...messages.commitText} />
      </Message>
      <Message>
        <FormattedMessage {...messages.annoucementText} />
      </Message>
      <Message>
        <FormattedMessage {...messages.introduceGyomuText} />
      </Message>
      <Message>
        <FormattedMessage {...messages.collaborationText} />
      </Message>
      <Message>
        <FormattedMessage {...messages.infoText} />
      </Message>
      <Message>
        <FormattedMessage {...messages.inviteText} />
      </Message>
    </MessageWrapper>
  );
};

export default GyomuSuperMessage;
